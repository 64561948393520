<template>
  <v-card class="mx-auto" elevation="5" width="100%" max-width="38rem" rounded="xl">
    <v-card v-if="!isLoading" width="100%" max-width="30rem" class="mt-5 mx-auto" elevation="0">
      <v-card-title>
        <h1 class="font-weight-bold text-center mt-5 mb-0">
          {{ resultTitle }}
        </h1>
      </v-card-title>

      <v-card-text class="pb-10">
        <v-row align="center" justify="center" class="pb-4">
          <v-col v-if="errorOccured" cols="12">
            <p class="text-subtitle-1 text-center pa-0 mb-0">
              {{ error }}
            </p>
          </v-col>

          <v-col v-else cols="12">
            <p class="text-subtitle-1 text-center pa-0 mb-0">
              {{ $t('login.your_registration_done_text') }}
            </p>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" x-large block @click="$router.push({ name: 'login' })">
              {{ $t('login.log_in') }}
            </v-btn>

            <img
              v-if="isProd && isTrackingCodeVisible"
              src="https://px.ads.linkedin.com/collect/?pid=2219180&conversionId=2391596&fmt=gif"
              alt="linkedin"
              height="1"
              width="1"
              class="d-none"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-else width="100%" max-width="28rem" class="mt-5 mx-auto" elevation="0">
      <v-col cols="12" class="my-12 text-center">
        <div class="loader"></div>
      </v-col>
    </v-card>
  </v-card>
</template>

<script>
import { confirmAccount } from '@access/services/accessService';

export default {
  name: 'ConfirmRegistration',
  data() {
    return {
      loaded: false,
      error: null,
      tracking: false,
    };
  },
  computed: {
    isProd() {
      return 'production' === process.env.NODE_ENV;
    },
    isLoading() {
      return !this.loaded;
    },
    isTrackingCodeVisible() {
      return !!this.tracking;
    },
    errorOccured() {
      return !!this.error;
    },
    resultTitle() {
      return this.$t(
        `${
          this.errorOccured
            ? 'login.error_during_registration'
            : 'login.your_registration_done_heading'
        }`,
      );
    },
  },
  created() {
    confirmAccount({
      confirmToken: this.$route.params.token,
    })
      .then((response) => {
        window.dataLayer.push({
          event: 'register',
          category: 'user',
          action: 'register',
          label: 'successful',
          user_id: response.data.email,
        });
        this.tracking = true;
      })
      .catch((err) => {
        const error = err?.response?.data;

        if (400 === error.code) {
          this.error = error?.message;
        } else {
          this.error = this.$t('login.confirm.error');
        }
      })
      .finally(() => {
        this.loaded = true;
      });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  animation: rotation 4s infinite linear;
  width: 100px;
  height: 100px;
  background-size: contain;
  background: url('/images/loader.svg') no-repeat center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
